<div class="d-flex" style="height: {{ altura }}px;">
    <div class="row justify-content-center align-self-center m-4">
        <div class="col-12 text-center mb-3">
            <span style="font-size: 65px; font-weight: 500; color: #777;">Dieta</span>
        </div>
        <div class="col-12">
            <mat-form-field class="example-form-field">
                <mat-label>Usuário</mat-label>
                <input matInput type="text" [(ngModel)]="usuario" name="usuario" (keyup.enter)="login()">
                @if (usuario) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="usuario=''">
                    <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>

        <div class="col-12">
            <mat-form-field class="example-form-field">
                <mat-label>Senha</mat-label>
                <input matInput type="password" [(ngModel)]="senha" name="senha" (keyup.enter)="login()">
                @if (senha) {
                    <button matSuffix mat-icon-button aria-label="Clear" (click)="senha=''">
                    <mat-icon>close</mat-icon>
                    </button>
                }
            </mat-form-field>
        </div>

        <div class="col-12">
            <button class="btn btn-block btn-outline-secondary" (click)="login()">Entrar</button>
        </div>

    </div>
</div>
