import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { URL } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class DataService {
    constructor(private http: HttpClient,
                private auth: AuthService){ }

    private notificacaoResumoSource = new BehaviorSubject("");
    notificacaoResumo = this.notificacaoResumoSource.asObservable();
    atualizarResumo() {
        this.notificacaoResumoSource.next("")
    }

    login(dados: any): Observable<any> {
        const headers = { headers: new HttpHeaders({ 'Authorization': 'application/json' }) };
        return new Observable((subscriber) => {
            this.http.post(URL.URL_LOGIN, dados, headers).subscribe({
                next: (response: any) => {
                    subscriber.next(response);
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    getResumo(dia: any = ""): Observable<any>{
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        const filtroDia = dia == "" ? "" : '?dia=' + dia;
        return new Observable((subscriber) => {
            this.http.get(URL.URL_RESUMO + filtroDia, headers).subscribe({
                next: (response: any) => {
                    subscriber.next({ ok: true, msg: "", data: response.data });
                    // subscriber.complete();Q
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    listaAlimentos(pesquisar: any): Observable<any> {
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        return new Observable((subscriber) => {
            this.http.get(URL.URL_LISTA_ALIMENTOS, headers).subscribe({
                next: (response: any) => {
                    subscriber.next({ ok: true, msg: "", data: response.data });
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    adicionarAlimento(alimento: any): Observable<any>{
        // const headers = { headers: new HttpHeaders({ 'Authorization': 'application/json' }) };
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        // const headers = {
        //     headers: new HttpHeaders({
        //         'Authorization': 'application/json',
        //         'Authorization': this.auth.getToken()
        //     })
        // };

        return new Observable((subscriber) => {
            this.http.post(URL.URL_ADICIONAR_ALIMENTO, alimento, headers).subscribe({
                next: (response: any) => {
                    if(response.ok){
                        subscriber.next({ ok: true, msg: "", data: response.data });
                        this.atualizarResumo();
                    }
                    else{
                        subscriber.next({ ok: false, msg: response.msg, data: null });
                    }
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    getMetaAtual(): Observable<any> {
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        return new Observable((subscriber) => {
            this.http.get(URL.URL_GET_META, headers).subscribe({
                next: (response: any) => {
                    subscriber.next({ ok: true, msg: "", data: response.data });
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    adicionarMeta(meta: any): Observable<any>{
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        return new Observable((subscriber) => {
            this.http.post(URL.URL_ADICIONAR_META, meta, headers).subscribe({
                next: (response: any) => {
                    if(response.ok){
                        subscriber.next({ ok: true, msg: "", data: response.data });
                        this.atualizarResumo();
                    }
                    else{
                        subscriber.next({ ok: false, msg: response.msg, data: null });
                    }
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    getListaAlimentoExterno(texto: string): Observable<any> {
        let fd = new FormData();
        fd.append('search', texto);
        return new Observable((subscriber) => {
            this.http.post(URL.URL_LISTA_ALIMENTO_EXTERNO, fd, { responseType: 'text' }).subscribe({
                next: (response: any) => {
                    const regex = /<li data-breadcrumbs='add' data-indice='([0-9]*)'>(.*)<\/li>/gm;
                    response = response.replaceAll("><", ">\n<");
                    response = response.split("\n");
                    response = response.map((item: any) => {
                        item = item.replace(regex, `$1(|)$2`).split('(|)');
                        return { id: item[0], nome: item[1] };
                    });

                    if(response.length == 1 && response[0].nome == undefined){
                        response = [];
                    }
                    subscriber.next({ ok: true, msg: "", data: response });
                    subscriber.complete();
                },
                error: (error: any) => {
                    console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    getAlimentoExterno(texto: string): Observable<any> {
        let fd = new FormData();
        fd.append('indice', texto);
        return new Observable((subscriber) => {
            this.http.post(URL.URL_ALIMENTO_EXTERNO, fd, { responseType: 'text' }).subscribe({
                next: (response: any) => {
                    let doc = new DOMParser().parseFromString(response, "text/html");
                    let alimento = {
                        calorias:     doc!.querySelector('.result-info #calorias')!.getAttribute('data-value'),
                        carboidrato:  doc!.querySelector('.result-info #carboidratos')!.getAttribute('data-value'),
                        proteina:     doc!.querySelector('.result-info #proteina')!.getAttribute('data-value'),
                        gordura:      doc!.querySelector('.result-info #gorduratotal')!.getAttribute('data-value'),
                        nome:         doc!.querySelectorAll('.result-header p')[0]!.textContent,
                        unidade:      doc!.querySelectorAll('.result-header p')[1]!.textContent,
                    }

                    alimento.nome    = alimento.nome!.replace(/\( Código: .* \)/gm, '');
                    alimento.unidade = alimento.unidade!.replace('Quantidade: ', '');

                    subscriber.next({ ok: true, msg: "", data: alimento });
                    subscriber.complete();
                },
                error: (error: any) => {
                    console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }

    salvarAlimentoNovo(alimento: any): Observable<any>{
        const headers = { headers: new HttpHeaders({ 'Authorization': this.auth.getToken() }) };
        return new Observable((subscriber) => {
            this.http.post(URL.URL_SALVAR_ALIMENTO_NOVO, alimento, headers).subscribe({
                next: (response: any) => {
                    if(response.ok){
                        subscriber.next({ ok: true, msg: "", data: response.data });
                    }
                    else{
                        subscriber.next({ ok: false, msg: response.msg, data: null });
                    }
                    subscriber.complete();
                },
                error: (error: any) => {
                    // console.log(error);
                    let msg;
                    if(error.error != undefined && error.error.description != undefined){
                        msg = error.error.description;
                    }
                    else{
                        msg = "Erro não identificado";
                    }
                    subscriber.next({ ok: false, msg: msg, data: error.data });
                    subscriber.complete();
                }
            });
        });
    }
}
