export const environment = {
  production: true
};

const urlBase = 'https://dieta.elzin.xyz/api';
export const URL = {
    URL_LISTA_ALIMENTOS:        urlBase + '/listaAlimentos.php',
    URL_ADICIONAR_ALIMENTO:     urlBase + '/adicionarAlimento.php',
    URL_RESUMO:                 urlBase + '/resumo.php',
    URL_GET_META:               urlBase + '/getMeta.php',
    URL_ADICIONAR_META:         urlBase + '/adicionarMeta.php',
    URL_LOGIN:                  urlBase + '/login.php',
    URL_LISTA_ALIMENTO_EXTERNO: urlBase + '/listaAlimentoExterno.php',
    URL_ALIMENTO_EXTERNO:       urlBase + '/alimentoExterno.php',
    URL_SALVAR_ALIMENTO_NOVO:   urlBase + '/salvarAlimentoNovo.php',
};
