import { Component } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { AuthService } from 'src/app/services/auth.service';

import { FormsModule } from '@angular/forms';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';

@Component({
    selector: 'login',
    templateUrl: 'login.component.html',
    styleUrls: ['login.component.scss'],
    standalone: true,
    imports: [FormsModule, MatFormFieldModule, MatInputModule, MatIconModule],
})
export class LoginComponent {
    public altura   = window.innerHeight;
    public usuario  = "";
    public senha    = "";
    constructor(private dataService: DataService, private authService: AuthService){
        if(this.authService.isLogged()){
            window.location.href = "/";
        }
    }

    login(){
        this.dataService
            .login({ usuario: this.usuario, senha: this.senha })
            .subscribe({
                next: (response: any) => {
                    if(response.ok){
                        this.authService.login(response.data);
                        window.location.href = "/";
                    }
                    else{
                        alert(response.msg);
                    }
                },
                error: (error: any) => {
                    console.log("Erro na resposta do dataService.");
                },
            });
    }
}
